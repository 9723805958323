/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import groupBy from 'lodash.groupby';
import React, { memo } from 'react';
import nextId from 'react-id-generator';
import slugify from 'slugify';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import Layout from '../components/Layout';
import NewsletterFormSidebar from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';

const config = resolveConfig(tailwindConfig);
const {
  theme: {
    colors: { primary },
  },
} = config;

export default memo(({ data }) => {
  const { posts, books } = data;

  const allPosts = [...posts.nodes, ...books.nodes].map((p) => p.frontmatter);
  const groupedYear = groupBy(allPosts, 'published_year');

  const archiveList = [];
  const image = getImage(data.heroBg);

  const imgStack = ['linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.7))', image];

  Object.keys(groupedYear).forEach((key) => {
    if (key) {
      archiveList.push({
        elements: groupBy(groupedYear[key], 'published_month_no'),
        key,
      });
    }
  });
  archiveList.reverse();

  return (
    <Layout progress>
      <SEO />
      <BgImage
        css={css`
          &::after,
          &::before {
            background-position: 50% 10%;
            background-size: cover;
          }
        `}
        style={{ backgroundPosition: '', backgroundSize: '' }}
        image={imgStack}
      >
        <div>
          <div className="container py-12  md:pb-24 md:pt-24 ">
            <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">Archive</h1>
          </div>
        </div>
      </BgImage>
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div
            className="md:w-3/4 w-full md:border-r md:pr-6"
            css={css`
              border-color: var(--border);
            `}
          >
            <div
              className="border p-4 mb-12 flex flex-wrap rounded"
              css={css`
                color: var(--textNormal);
                border-color: var(--border);
                background-color: var(--bgBadge);
              `}
            >
              {groupedYear &&
                Object.keys(groupedYear)
                  .reverse()
                  .map((year) => (
                    <a
                      key={year}
                      className="mr-4 font-mono hover:text-blue-500"
                      href={`#year-${year}`}
                      onClick={(e) => {
                        if (typeof window !== 'undefined') {
                          e.preventDefault();
                          document.querySelector(`#year-${year}`).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                    >
                      {year}
                    </a>
                  ))}
            </div>
            {archiveList &&
              Object.entries(archiveList).map(([index, entry]) => {
                const { key, elements } = entry;

                return (
                  <div
                    key={index}
                    className="pb-12"
                    css={css`
                      position: relative;
                      &:before {
                        content: '';
                        position: absolute;
                        left: -22px;
                        width: 2px;
                        height: 100%;
                        background: ${primary[400]};
                      }
                    `}
                  >
                    <h3 className="font-semibold text-primary-600 mt-0" id={`year-${key}`}>
                      <div
                        className="w-3 h-3 rounded-full bg-primary-600 inline-block relative  mr-0"
                        css={css`
                          top: -2px;
                          margin-left: -27px;
                        `}
                      />{' '}
                      Year {key}
                    </h3>
                    <div>
                      {Object.keys(elements).map((article) => (
                        <>
                          {' '}
                          <h5 className="text-gray-500 text-base uppercase mb-0 font-semibold">
                            <div
                              className="w-3 bg-gray-500 inline-block relative -ml-4 mr-0"
                              css={css`
                                top: -4px;
                                height: 2px;
                              `}
                            />{' '}
                            {elements[article][0].published_month}
                          </h5>
                          {elements[article] &&
                            elements[article].map((item) => <Item key={nextId()} item={item} />)}
                        </>
                      ))}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="md:w-1/4 w-full md:pl-6 ">
            <NewsletterFormSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
});

const Item = ({ item }) => (
  <React.Fragment key={item.slug}>
    {item.slug ? (
      <div
        className="py-4 border-b"
        css={css`
          border-color: var(--border);
          &:last-of-type {
            border-bottom: none;
          }
        `}
      >
        <Link to={`${item.slug}`}>
          <h2 className="text-lg my-0 text-primary-500">
            {item.layout === 'book' && (
              <i className="fas fa-book text-base text-gray-600 mr-2">
                <span className="font-mono pl-1">Book</span>
              </i>
            )}{' '}
            {item.title}
          </h2>
          {item.layout === 'book' && (
            <div className="text-gray-600 py-2 font-sans text-sm">By {item.bookAuthor}</div>
          )}{' '}
        </Link>{' '}
        <div className="text-xs">
          {item.categories &&
            item.categories.map((category, i) => (
              <React.Fragment key={nextId()}>
                {' '}
                {i !== 0 && ', '}{' '}
                <Link
                  to={`/category/${slugify(category.toLowerCase())}`}
                  className="underline font-mono"
                  css={css`
                    color: var(--textSubTag);
                  `}
                >
                  {category}
                </Link>
              </React.Fragment>
            ))}
        </div>
      </div>
    ) : null}
  </React.Fragment>
);

export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "archive-header-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
      }
    }
    posts: allBlogPost(filter: { frontmatter: { draft: { ne: true } } }) {
      nodes {
        frontmatter {
          title
          slug
          layout
          published_year
          published_month
          published_day
          published_month_no
          date(formatString: "DD MMMM YYYY")
          categories
        }
      }
    }
    books: allBook {
      nodes {
        frontmatter {
          title
          slug
          layout
          bookAuthor
          published_year
          published_month
          published_day
          published_month_no
          date(formatString: "DD MMMM YYYY")
          categories
        }
      }
    }
  }
`;
